import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  MRT_Table as MrtTable,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { getInitialsByWords } from "utils";
import { generateDates } from "./helpers";
import TaskCard from "./TaskCard";
import { filter, find, uniq } from "lodash";
import { Box, IconButton } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DailyReportToolbar from "pages/dailyReport/DailyReportToolbar";

const columnHeaderDateFormat = "ddd, MM/DD";
const cellIdFormat = "MM-DD-YYYY";

const AssignmentsTable = ({
  taskGridRef,
  resources,
  assignmentsData,
  dateToApply,
  timeLine,
  tagsData,
  allTasksToggle,
  isDailyReportView,
  isReadOnly,
  showWeekend,
  handleSubtaskChange,
  handleAddTag,
  handleDeleteTag,
  handleDeleteTask,
  handleDeleteResource,
  updateDurationVal,
  updateNotesVal,
  handleAllTaskToggle,
  handleAddProjResource,
}) => {
  const { holidays, completedDates } = useSelector(
    (state) => state.lookaheadData
  );

  const generateTaskCard = useCallback(
    (rowData, date, isComplete = false) => {
      if (taskGridRef && assignmentsData.length > 0) {
        const { row } = rowData;
        const filterTask = filter(assignmentsData, (assignment) => {
          return (
            assignment.resourceId === row.original.Contact__c &&
            moment(assignment.startDate).format("YYYY-MM-DD") ===
              moment(date).format("YYYY-MM-DD") &&
            !assignment.deleted &&
            !assignment.isDeleted &&
            !assignment.rDeleted
          );
        });
        if (taskGridRef && filterTask.length > 0) {
          const taskArray = filterTask.map((ft) => {
            const { eventId, duration } = ft;
            const taskFromStoreData = taskGridRef.store.getById(eventId);
            if (taskFromStoreData) {
              const taskData = {
                ...ft,
                name: taskFromStoreData.data.name,
                parentId: taskFromStoreData.parentId,
                parentName: taskFromStoreData.parentId
                  ? taskFromStoreData.parent.data.name
                  : taskFromStoreData.data.name,
                siblings: taskFromStoreData.parent.children.map((c) => ({
                  label: c.name,
                  id: c.id,
                  key: c.id,
                })),
              };
              return (
                <Box
                  className="lh-task-dropable-box"
                  key={taskData.id}
                  id={taskData.id}
                >
                  <TaskCard
                    row={row.original}
                    date={date}
                    taskData={taskData}
                    tagsData={tagsData}
                    allTasksToggle={allTasksToggle}
                    isReadOnly={isReadOnly || isComplete}
                    handleSubtaskChange={handleSubtaskChange}
                    handleAddTag={handleAddTag}
                    handleDeleteTag={handleDeleteTag}
                    handleDeleteTask={handleDeleteTask}
                    updateDurationVal={updateDurationVal}
                    updateNotesVal={updateNotesVal}
                  />
                </Box>
              );
            } else {
              return null;
            }
          });
          return taskArray;
        } // end filtertask
      }
      return <Box className="lh-task-dropable-box"></Box>;
    },
    [assignmentsData, taskGridRef, allTasksToggle, isReadOnly]
  );

  const crewFilterOptions = uniq(resources.map((r) => r.Crew__r));
  const checkIfDropAvailable = (date, row) => {
    const { original } = row;
    let showTimeOff = null;
    if (original.timeOffDates && original.timeOffDates.length > 0) {
      showTimeOff = find(
        original.timeOffDates,
        (tf) => tf.offDate === moment(date).format("YYYY-MM-DD")
      );
    }
    return showTimeOff;
  };

  const checkHoliday = (date) => {
    let showHoliday = find(
      holidays,
      (h) => h.ActivityDate === moment(date).format("YYYY-MM-DD")
    );
    return showHoliday;
  };

  const checkComplete = (date) => {
    let complete = completedDates.includes(moment(date).format("YYYY-MM-DD"));
    return complete;
  };

  const getUtilization = (row) => {
    if (assignmentsData?.length === 0) {
      return <span className="lh-utilization lh-less">0%</span>;
    }
    const resId = row.original.id;
    const totalHrs = assignmentsData
      .filter((a) => a.resourceId === resId && !a.deleted)
      .reduce((p, c, a) => p + Number(c.duration), 0);
    
    const timeDuration = (timeLine === 1) ? 8 : (timeLine === 7 && showWeekend)? 56 : (timeLine === 7 && !showWeekend)? 40: (showWeekend)? 112: 80;
    const utilization = ((totalHrs / timeDuration) * 100).toFixed(0);
    return (
      <span
        className={`lh-utilization ${
          Number(utilization) === 100
            ? "lh-full"
            : Number(utilization) < 100
            ? "lh-less"
            : "lh-over"
        }`}
      >
        {utilization}%
      </span>
    );
  };

  const [showColumns, setShowColumns] = useState(true);
  const toggleColumns = () => {
    setShowColumns(!showColumns);
  };

  const columns = useMemo(() => {
    const columnDates = generateDates(dateToApply, timeLine, showWeekend);
    const dateGroupColumnHeader =
      timeLine === 1
        ? moment(dateToApply).format(columnHeaderDateFormat)
        : moment(dateToApply).weekday(1).format(columnHeaderDateFormat);

    return [
      {
        id: "resourceGroup",
        header: "",
        Header: () => {
          return (
            <div className="lh-show-hide-columns">
              <IconButton aria-label="show-hide" size="small" onClick={toggleColumns}>
                {showColumns ? (
                  <KeyboardDoubleArrowLeftIcon
                    fontSize="inherit"
                  />
                ) : (
                  <KeyboardDoubleArrowRightIcon
                    fontSize="inherit"
                  />
                )}
              </IconButton>
            </div>
          );
        },
        enablePinning: true,
        maxSize: 270,
        size: 270,
        columns: [
          {
            header: "Resource",
            accessorKey: "name",
            filterVariant: "text",
            maxSize: 90,
            size: 90,
            // minSize: 90,
            enablePinning: true,
            Cell: ({ row }) => (
              <div>
                <p className="lh-name">
                  {isDailyReportView && (
                    <>
                      {!row.original.timeCardAvailable && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          className="lh-delete-assignment-btn"
                          onClick={() =>
                            handleDeleteResource({
                              Id: row.original.id,
                              Name: row.original.name,
                            })
                          }
                        >
                          <DeleteIcon fontSize="12px" />
                        </IconButton>
                      )}
                      {row.original.timeCardAvailable && (
                        <CheckBoxIcon color={"success"} />
                      )}
                    </>
                  )}
                  {!isDailyReportView && (
                    <CheckBoxIcon
                      color={
                        row.original.timeCardAvailable ? "success" : "disabled"
                      }
                    />
                  )}
                  {row.original.name}
                  {/* <span className="lh-role">
                    {getInitialsByWords(row.original.role)}
                  </span> */}
                  {isDailyReportView &&
                    row.original.isProjectResource === false &&
                    (row.original.status === "Active" || row.original?.timeCardDetail?.status === "Active") && (
                      <IconButton
                        onClick={() =>
                          handleAddProjResource({
                            Id: row.original.id,
                            Name: row.original.name,
                          })
                        }
                      >
                        <AddIcon fontSize="12px" />
                      </IconButton>
                    )}
                  {/* <span className="lh-utilization lh-full">100%</span> */}
                  {getUtilization(row)}
                </p>
              </div>
            ),
          },
          {
            header: "Role",
            accessorKey: "role",
            maxSize: 90,
            size: 90,
            enablePinning: true,
          },
          {
            header: "Crew",
            accessorKey: "Crew__r",
            filterVariant: "select",
            filterSelectOptions: crewFilterOptions,
            maxSize: 90,
            size: 90,
            // minSize: 90,
            enablePinning: true,
          },
          {
            header: "Accountable Resource",
            accessorKey: "Crew_Accountable",
            maxSize: 90,
            size: 90,
            // minSize: 90,
            enablePinning: true,
          },
        ],
      },
      {
        id: "dateGroup",
        header: dateGroupColumnHeader,
        Header: ({ column }) => {
          return (
            <div>
              {column.columnDef.header}
              {isDailyReportView && (
                <DailyReportToolbar handleAllTaskToggle={handleAllTaskToggle} />
              )}
            </div>
          );
        },
        enableColumnFilter: false,
        columns: columnDates.map((d) => ({
          header: moment(d).format(columnHeaderDateFormat),
          // accessorKey: "id",
          enableSorting: false,
          enableColumnFilter: false,
          // size: "auto",
          Cell: (dataRow) => {
            const { row } = dataRow;
            let showTimeOff = checkIfDropAvailable(d, row);
            if (showTimeOff) {
              return (
                <div
                  className={`lh-task-non-dropable-td ${
                    (timeLine === 1 || isDailyReportView) &&
                    "lh-task-dropable-td-1day"
                  }`}
                >
                  {(showTimeOff.payCodeObj.isTimeOff || !showTimeOff.payLabel) ? "Time Off" : showTimeOff.payLabel}
                </div>
              );
            }
            let isHoliday = checkHoliday(d);
            let isComplete = checkComplete(d);
            if (isHoliday) {
              return (
                <div
                  className={`lh-task-non-dropable-td ${
                    (timeLine === 1 || isDailyReportView) &&
                    "lh-task-dropable-td-1day"
                  }`}
                >
                  {isHoliday?.Name}
                </div>
              );
            }
            if (isComplete) {
              return (
                <div
                  className={`lh-task-dropable-td ${
                    (timeLine === 1 || isDailyReportView) &&
                    "lh-task-dropable-td-1day"
                  }`}
                >
                  {generateTaskCard(dataRow, d, true)}
                </div>
              );
            }
            return (
              <div
                className={`lh-task-dropable-td ${
                  (timeLine === 1 || isDailyReportView) &&
                  "lh-task-dropable-td-1day"
                }`}
              >
                {generateTaskCard(dataRow, d)}
              </div>
            );
          },
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: ({ cell }) => {
            const { row } = cell;
            let showTimeOff = checkIfDropAvailable(d, row);
            let isHoliday = checkHoliday(d);
            let isComplete = checkComplete(d);
            let className = `${moment(d).format(cellIdFormat)}_${
              cell.row.original.Contact__c
            }`;
            if (showTimeOff || isHoliday || isComplete) {
              className += "lh-task-dropable-td";
            }
            return {
              className,
              id: `${moment(d).format(cellIdFormat)}_${
                cell.row.original.Contact__c
              }`,
            };
          },
        })),
      },
    ];
  }, [
    dateToApply,
    timeLine,
    assignmentsData,
    resources,
    allTasksToggle,
    isReadOnly,
    showWeekend,
    showColumns
  ]);

  const [columnOrder, setColumnOrder] = useState([]);

  useEffect(() => {
    const columnDates = generateDates(dateToApply, timeLine, showWeekend);
    setColumnOrder(() => {
      return columnDates.map((d) => moment(d).format(columnHeaderDateFormat));
    });
  }, [dateToApply, timeLine, showWeekend]);

  const table = useMaterialReactTable({
    columns,
    data: resources, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowSelection: false, //enable some features
    enableColumnOrdering: false, //enable a feature for all columns
    enableGlobalFilter: false, //turn off a feature
    enableDensityToggle: false,
    enableColumnFilters: true,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    enablePagination: false,
    // layoutMode: 'grid-no-grow',
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ["name", "role", "Crew__r", "Crew_Accountable"],
        right: [],
      },
    },
    state: {
      columnOrder,
    },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    columnFilterDisplayMode: "popover",
    muiTableContainerProps: {
      sx: {
        maxHeight: isDailyReportView
          ? "calc(100vh - 205px)"
          : "calc(100vh - 130px)",
      },
    },
    muiTableHeadRowProps: {
      sx: {
        "& th:nth-of-type(1)": {
          background: "#e3e3e3",
        },
        "& th:nth-of-type(2)": {
          background: "#e3e3e3",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid #ccc",
        fontWeight: "bold",
        background: "#e7e7e7",
      },
    },
    muiTableBodyRowProps: {
      sx: {
        "& td:nth-of-type(1)": {
          background: "#e3e3e3",
          borderRight: "1px solid #aaa",
          borderBottom: "1px solid #aaa",
        },
        "& td:nth-of-type(2)": {
          background: "#e3e3e3",
          borderRight: "1px solid #aaa",
          borderBottom: "1px solid #aaa",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ddd",
        padding: "4px",
        overflow: "visible",
      },
    },
  });

  useEffect(() => {
    table.setColumnVisibility({ role: showColumns, Crew__r: showColumns, Crew_Accountable: showColumns });
  }, [showColumns])

  return <MaterialReactTable table={table} />;
};

export default AssignmentsTable;
